import React from 'react';
import PropTypes from 'prop-types';
import './MediclubBrandName.css'; // CSS styles for the component

const MediclubBrandName = ({title}) => {
  return (
    <div className="mediclub-brand-name"> 
      <h3 className="brand-title">{title}</h3>
     </div>
  );
};

MediclubBrandName.propTypes = {  //Parameters to request
  title: PropTypes.string.isRequired,
};

export default MediclubBrandName;