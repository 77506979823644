import React, { useState } from 'react';
import './App.css';
import MainPage from './Pages/MainPage'
import ProductPage from './Pages/ProductPage';
import LoginMediClub from './Pages/Login/LoginMediClub.jsx';

function App() {
  
  const [currentPage, setCurrentPage] = useState('login'); //Main page 

  //Function to change page
  const changePage = (newPage) => {
    setCurrentPage(newPage);
  }

  //Render the actual page
  const renderPage = () => {
    switch (currentPage) {
      case 'login':
        return <LoginMediClub onPageChange={changePage}/>
      case 'main':
        return <MainPage onPageChange={changePage} />
      case 'Product':
        return <ProductPage  onPageChange={changePage}/>
      default:
        return <MainPage onPageChange={changePage}/>
    }
  };

  return (
    <div>
      {renderPage()}
    </div>
  );
}
export default App;
