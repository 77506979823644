// Dropdown.jsx
import { useState } from 'react';
import styles from './DropdownCategories.module.css'; // Import the CSS module

const Dropdown = () => {
  // State to manage the selected value
  const [selectedValue, setSelectedValue] = useState('');

  // Event handler for changing the selected value
  const handleSelectChange = (event) => {
    setSelectedValue(event.target.value);
  };
  const categories = [
    {name: 'Best Seller'},
    {name: 'Recomended for you'},
    {name: 'appliances'}
  ];

  return (
    <div className={styles['dropdown-container' ]}>
      <select style={{ float: 'right' }} className={styles['dropdown-select']} id="dropdown" value={selectedValue}>
        <option value="">Categories</option>
        {categories.map((category)=>(
          <option value= {category.name}>{category.name}</option>
        ))}
      </select>
      {/* Display the selected value */}
    </div>
  );
};

export default Dropdown;

