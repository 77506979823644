import React, { useState } from 'react';
import ResponsiveAppBar from '../Components/ResponsiveAppBar';
import soloLogo from '../soloLogo.png';
import nombreLogo from '../nombreLogo.png';

const ProductPage = ({onPageChange}) => {
    return (
        <div className="App">
            <ResponsiveAppBar onPageChange={onPageChange} /> {/* Comments */}
            <div style={{ width: '100%', height: 100, backgroundColor: '#282c34' }}> {/* Main manu App bar  */}
                <div>{/* Logo image in App bar */}
                    <img style={{
                        position: 'absolute',
                        top: 80,
                        left: 20,
                        height: 80,
                    }} src={soloLogo} className='App-Logo' alt='logo' />
                    {/* Logo image in Main page */}
                    <img style={{
                        position: 'absolute',
                        top: 95,
                        left: 100,
                        height: 50,
                    }} src={nombreLogo} className='App-Logo' alt='logo' />
                </div>
            </div>
        </div>
    )
}

export default ProductPage;