import React, { useState } from 'react';
import styled from 'styled-components';
import './LoginMediClub.css'
import ResponsiveAppBar from '../../Components/ResponsiveAppBar';
import logo from '../../mediclubjk_logo2.png'

const LoginForm = styled.div`
    max-width: 300px;
    margin: auto;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    `;

const LoginButton = styled.button`
    background-color: #4caf50;
    color: white;
    padding: 10px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    `;

const LoginMediClub = ({onPageChange}) => {
    const [user, setUser] = useState('');
    const [password, setPassword] = useState('');
    const [loginError, setLoginError] = useState(false);

    const correct_User= 'admin';
    const correct_Password= 'admin1234';

    const handleLoginSuccess = ()=>{
        if (!loginError){
            onPageChange('main')
        }
    }

    const handleUserChange = (e) => {
        setUser(e.target.value);
    }

    const handlePasswordChange = (e) => {
        setPassword(e.target.value);
    }

    const handleLogin = () => {
        //Implementing login logic here 
        // At the moment we don´t need to connect or do any request to the backend
        if (user=== correct_User && password === correct_Password){
            console.log('Login successfull');
            setLoginError(false);
            handleLoginSuccess()
        } else {
            console.log('Login failed. Invalid credentials');
            setLoginError(true);
        }
    }

    return (
        <div>
            <ResponsiveAppBar />
            <div style={{textAlign: 'center'}}>
                <img alt= 'logo' src= {logo} style={{height: 'auto', width: '45%'}}></img>
            </div>
            <div >
            <LoginForm>
                <h2>Login</h2>
                <form>
                    <div style={{textAlign: 'right'}}>
                        <label>Usuario:</label>
                        <input 
                            type="user"
                            value={user}
                            onChange={handleUserChange}
                        />
                    </div>
                    <div style={{textAlign: 'right'}}>
                        <label>Contraseña:</label>
                        <input
                            type="password"
                            value={password}
                            onChange={handlePasswordChange}
                        />
                    </div>
                    <LoginButton type="button" onClick={handleLogin}>
                        Acceder
                    </LoginButton>

                    <a  href='' className='custom-style'>Registrarse</a>
                </form>
            </LoginForm>
            </div>
        </div>
    );
}

export default LoginMediClub;