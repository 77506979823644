import logo from '../logo.svg';
import '../App.css';
import ResponsiveAppBar from '../Components/ResponsiveAppBar';
import MediclubProductBox from '../Components/Global/MediclubProductBox';
import image01test from '../Components/ImagesTest/image01test.jpg'
import Estetoscopio from '../Components/ImagesTest/Estetoscopio.png'
import Jeringa from '../Components/ImagesTest/Jeringa.png'
import MangaDP from '../Components/ImagesTest/MangaDP.png'
import Nebulizador from '../Components/ImagesTest/Nebulizador.png'
import Otoscopio from '../Components/ImagesTest/Otoscopio.png'
import soloLogo from '../soloLogo.png';
import nombreLogo from '../nombreLogo.png';
import MediclubProductCarousel from '../Components/Global/MediclubProductCarousel';
import MediclubBrands from '../Components/Global/MediclubBrands'
import MediclubBrandName from '../Components/Global/MediclubBrandName';
import DropdownCategories from '../Components/Global/DropdownCategories'



const MainPage = ({onPageChange}) => {
  
  const handlePageChange= (newPage)=>{
    onPageChange(newPage); //Call the function in App.js to change page
  }

  const products = [
    { title: 'Product 1', rating: 4.1, imageSrc: image01test, price: 100 }, //local info to visualice carousel functionality
    { title: 'Product 2', rating: 4.2, imageSrc: image01test, price: 200 },
    { title: 'Product 3', rating: 4.3, imageSrc: image01test, price: 300 },
    { title: 'Product 4', rating: 4.4, imageSrc: image01test, price: 400 },
    { title: 'Product 5', rating: 4.5, imageSrc: image01test, price: 500 },
    { title: 'Product 6', rating: 4.6, imageSrc: image01test, price: 600 },
    { title: 'Product 7', rating: 4.7, imageSrc: image01test, price: 700 },
    { title: 'Product 8', rating: 4.8, imageSrc: image01test, price: 800 },
    { title: 'Product 9', rating: 4.9, imageSrc: image01test, price: 900 }
  ];
  const bestSellers = [
    { title: 'Product 1', rating: 75, imageSrc: Jeringa, price: 100 }, //local info to visualice carousel functionality
    { title: 'Product 2', rating: 54, imageSrc: MangaDP, price: 200 },
    { title: 'Product 3', rating: 20, imageSrc: Nebulizador, price: 300 },
    { title: 'Product 4', rating: 14, imageSrc: Estetoscopio, price: 400 },
    { title: 'Product 5', rating: 10, imageSrc: Otoscopio, price: 500 },
  ];
  const brands = [
    {title: 'Leica'},
    {title: 'Evident'},
    {title: 'RaySfe'},
    {title: 'OptoMedic'},
    {title: 'Fluke'}
  ];
  const recommendations = [
    { title: 'Product 1', rating: 'a', imageSrc: Jeringa, price: 100 }, //local info to visualice carousel functionality
    { title: 'Product 2', rating: 'b', imageSrc: MangaDP, price: 200 },
    { title: 'Product 3', rating: 'c', imageSrc: Nebulizador, price: 300 },
    { title: 'Product 4', rating: 'd', imageSrc: Estetoscopio, price: 400 },
    { title: 'Product 5', rating: 'e', imageSrc: Otoscopio, price: 500 },
  ];
  return (
    <div className="App">
      <ResponsiveAppBar onPageChange={onPageChange}/> {/* Comments */}
      <div style={{ width: '100%', height: 100, backgroundColor: '#282c34' }}> {/* Main manu App bar  */}
        <div>{/* Logo image in App bar */}
          <img style={{
            position: 'absolute',
            top: 80,
            left: 20,
            height: 80,
          }} src={soloLogo} className='App-Logo' alt='logo' />
          {/* Logo image in Main page */}
          <img style={{
            position: 'absolute',
            top: 95,
            left: 100,
            height: 50,
          }} src={nombreLogo} className='App-Logo' alt='logo' />
        </div>
        <div style={{ float: 'right' }}>
        <DropdownCategories />
        </div>
      </div>
      <div style={{ width: '100%', height: '100%', backgroundColor: '#282c34' }}>
        <MediclubProductCarousel title='Nuevos productos'> {/* Carousel implemented */}
          {/* Implementing product box */}
          {products.map((product) => (
            <MediclubProductBox
              title={product.title}
              imageSrc={product.imageSrc}
              price={product.price}
              rating={product.rating}
              onPageChange={handlePageChange}
            />
          ))}
        </MediclubProductCarousel>
      </div>
      <div style={{ width: '100%', height: '100%', backgroundColor: '#282c34' }}>
        <MediclubProductCarousel title='Lo más vendido'> {/* Carousel implemented */}
          {/* Implementing product box */}
          {recommendations.map((product) => (
            <MediclubProductBox
              title={product.title}
              imageSrc={product.imageSrc}
              price={product.price}
              rating={product.rating}
              onPageChange={handlePageChange}
            />
          ))}
        </MediclubProductCarousel>
      </div>
      <div style={{ width: '100%', height: '100%', backgroundColor: '#282c34' }}>
        <MediclubProductCarousel title='Recomendaciones para ti'>
          {/* Implementing product box */}
          {bestSellers.map((product) => (
            <MediclubProductBox
              title={product.title}
              imageSrc={product.imageSrc}
              price={product.price}
              rating={product.rating}
              onPageChange={handlePageChange}
            />
          ))}
        </MediclubProductCarousel>
      </div>
      <div style={{ width: '100%', height: 600, backgroundColor: '#282c34' }}>
        <h2>Nuestras Marcas: </h2>
        <MediclubBrands title='Nuestras marcas'> {/* Carousel implemented */}
        {/* Implementing brands box */}
          {brands.map((brands) => ( 
            <MediclubBrandName
              key={brands.title}
              title={brands.title}
            />
          ))}
        </MediclubBrands>
      </div>
    </div>
  )
}
export default MainPage;