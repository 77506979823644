import React, { useState } from 'react';
import PropTypes from 'prop-types';
import './MediclubProductBox.css'; // CSS styles for the component

const MediclubProductBox = ({ title, imageSrc, price, rating, onPageChange }) => {
  
  const handlePageChange = () => {
    onPageChange('Product');
  }

  return (
    <div className="mediclub-product-box"> 
      <img className="product-image" src={imageSrc} alt={title} />
      <h3 className="product-title">{title}</h3>
      <p className="product-price">${price}</p>
      <div className="product-rating">
        Rating: {rating}/5
      </div>
      <button className="buy-button" onClick={handlePageChange}>Buy Now</button>
    </div>
  );
};

MediclubProductBox.propTypes = {  //Parameters to request
  title: PropTypes.string.isRequired,
  imageSrc: PropTypes.string.isRequired,
  price: PropTypes.number.isRequired,
  rating: PropTypes.number.isRequired,
  pageState: PropTypes.string.isRequired,
};

export default MediclubProductBox;
