import react from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './MediclubBrands.css';

const MediclubBrands = ({children}) =>{
    
    const settings= {  //Defining carousel preferences. 
        dots: false,
        infinite: true,
        speed:500,
        slidesToShow: 4,
        slidesToScroll:4,
        responsive:[ //Defining responsive parameters for each case
        {
            breakpoint: 1024,
            settings:{
                slidesToShow: 3, 
                slidesToScroll: 3, 
                infinite: true,
                dots: true,
            },
        },
        {
            breakpoint:768,
            settings:{
                slidesToShow: 2, 
                slidesToScroll: 2, 
                infinite: true,
            },
        },
        {
            breakpoint: 480,
            settings:{
                slidesToShow: 2, 
                slidesToScroll: 2, 
                infinite: true,
            },
        },
        ],
        
    }
    return (
        <div className='brands-carousel'>
            <Slider {...settings}>
                {children}
            </Slider>
        </div>
    )
}
export default MediclubBrands;